let $ = require('jquery');
window.$ = window.jQuery = $;
require('./smartCombos.js');
require('ie-class');
require('hammerjs');
require('popper.js');
require('bootstrap');
/* If needed, add other dependencies here.

   Remember:
   - Use npm or yarn to install them.
   - Require them here, like `require('bootstrap');`
   - Leave the `$ = window.$` before the requires.
*/
$ = window.$;

/* 
  OFICIALES
*/
var oficiales = [
  {
    name: 'Jorge Garzón',
    imageUrl: 'https://itau.com.uy/imagen.jpg',
    accountType: 'Personas',
    tel: 26223344,
    int: 2909,
  },
  {
    name: 'Martín Molinari',
    imageUrl: 'https://itau.com.uy/imagen.jpg',
    accountType: 'PYMES',
    tel: 26223344,
    int: 2910,
  },
  {
    name: 'Mauricio Lapetina',
    imageUrl: 'https://itau.com.uy/imagen.jpg',
    accountType: 'Personal Bank',
    tel: 26223344,
    int: 2966,
  },
];

if (Array.isArray(oficiales) && oficiales.length === 1) {
  // strictly only one element
  console.log('tienes un solo oficial');
  $('#dropdown-chat #list-many-chats').hide();
  $('#dropdown-chat #list-one-chat').show();
} else {
  // not only one element
  console.log('tienes varios oficiales');
  $('#dropdown-chat #list-many-chats').show();
  $('#dropdown-chat #list-one-chat').hide();
}
